<template>
  <div class="login-container">
    <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        autocomplete="on"
        label-position="left"
    >
      <div class="title-container">
        <h3 class="title">会员登录</h3>
        <el-link class="registered" type="primary" @click="goRegistered"
        >免费注册
        </el-link
        >
      </div>

      <el-form-item prop="username">
        <span class="svg-container">
          <i class="el-icon-mobile-phone"/>
        </span>
        <el-input
            ref="username"
            v-model="loginForm.username"
            placeholder="请输入手机号"
            name="username"
            type="text"
            tabindex="1"
            autocomplete="on"
        />
      </el-form-item>

      <el-tooltip
          v-model="capsTooltip"
          content="Caps lock is On"
          placement="right"
          manual
      >
        <el-form-item prop="password">
          <span class="svg-container">
            <i class="el-icon-lock"/>
          </span>
          <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="请输入密码"
              name="password"
              tabindex="2"
              autocomplete="on"
              @keyup.native="checkCapslock"
              @blur="capsTooltip = false"
              @keyup.enter.native="handleLogin"
          />
          <span class="show-pwd" @click="showPwd">
            <i
                :class="
                passwordType === 'password'
                  ? 'el-icon-turn-off'
                  : 'el-icon-open'
              "
            />
          </span>
        </el-form-item>
      </el-tooltip>
      <!-- 滑块验证 -->
      <el-form-item>
        <drag-verify-com ref="dragVerify"
                         :width="448"
                         :height="45"
                         completedBg="#93a6ff"
                         :isPassing.sync="isSubmit"
                         text="请按住滑块拖动"
                         successText="验证通过"
                         handlerIcon="el-icon-d-arrow-right"
                         successIcon="el-icon-circle-check">
          <i v-if="!isSubmit"
             slot="textBefore"
             class="el-icon-lock">
          </i>
        </drag-verify-com>
        <!--        <slide-verify-->
        <!--          :l="42"-->
        <!--          :r="10"-->
        <!--          :w="448"-->
        <!--          :h="155"-->
        <!--          slider-text="向右滑动"-->
        <!--          @refresh="isSubmit = false"-->
        <!--          @success="onSuccess"-->
        <!--          @fail="()=>isSubmit = false"-->
        <!--        ></slide-verify>-->
      </el-form-item>

      <el-button
          :loading="loading"
          type="primary"
          size="medium"
          icon="el-icon-s-promotion"
          style="width: 100%; margin-bottom: 30px"
          @click.native.prevent="handleLogin"
      >登录
      </el-button
      >
    </el-form>
  </div>
</template>

<script>
// import {validUsername} from '@/utils/validate'
import dragVerify from "vue-drag-verify2";

export default {
  name: "Login",
  components: {
    'drag-verify-com': dragVerify
  },
  data() {
    // const validateUsername = (rule, value, callback) => {
    //   if (!validUsername(value)) {
    //     callback(new Error('Please enter the correct user name'))
    //   } else {
    //     callback()
    //   }
    // }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("The password can not be less than 6 digits"));
      } else {
        callback();
      }
    };
    return {
      isSubmit: false,
      loginForm: {
        username: "",
        password: "",
        // tmscode: "82289",
      },
      loginRules: {
        username: [{required: true, trigger: "blur", message: "请填写"}],
        // username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [
          // { required: true, trigger: "blur", validator: validatePassword },
          {required: true, trigger: "blur", message: "请填写"}
        ],
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  created() {
    // window.addEventListener('storage', this.afterQRScan)
  },
  mounted() {
    if (this.loginForm.username === "") {
      this.$refs.username.focus();
    } else if (this.loginForm.password === "") {
      this.$refs.password.focus();
    }
  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    onSuccess() {
      this.isSubmit = true
    },
    goRegistered() {
      this.$router.push({
        name: "registered",
      });
    },

    checkCapslock(e) {
      const {key} = e;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (!this.isSubmit) {
            this.$message({
              message: '请完成验证',
              type: 'error'
            })
            return
          }
          this.loading = true;
          this.$store
              .dispatch("user/login", this.loginForm)
              .then(() => {
                this.$message({
                  message: "登录成功",
                  type: "success",
                });
                this.$router.push({
                  path: "/",
                });
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }
  },
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $bg;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: calc(100vh - 105px);
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  background: url("../../assets/login/login-bg.png") no-repeat;
  background-size: 100% 100%;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 40px 35px 0;
    margin: 0 auto;
    margin-top: 120px;
    overflow: hidden;
    background: #fff;
    opacity: 0.8;
    border-radius: 4px;
    animation: flipInY 1s;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .registered {
      color: #1062f1;
      font-size: 14px;
      position: absolute;
      right: 3px;
      bottom: 8px;
      &:hover{
        animation: jello 1s;
      }
    }

    .title {
      font-size: 26px;
      color: #000;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
</style>
